body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.calendly-overlay {
  z-index: 10000000 !important;
}

html {
  height: 100%;
}

body > img {
  position: absolute;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.App {
  line-height: 1.5;
}

@font-face {
  font-family: Maison-Bold;
  src: local('MaisonNeue-Bold'), url(assets/fonts/MaisonNeue-Bold.ttf) format('truetype');
}

@font-face {
  font-family: Maison-Medium;
  src: local('MaisonNeue-Medium'), url(assets/fonts/MaisonNeue-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Maison-Book;
  src: local('MaisonNeue-Book'), url(assets/fonts/MaisonNeue-Book.ttf) format('truetype');
}

.clean-text-area {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
  border-image: none;
  border-radius: 6px;
  color: #555555;
  font-size: 1em;
  line-height: 1.4em;
  padding: 5px 8px;
  transition: background-color 0.2s ease 0s;
}


.clean-text-area:focus {
  background: none repeat scroll 0 0 #FFFFFF;
  outline-width: 0;
}

.clickable {
	cursor: pointer;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  user-drag: none;
  -webkit-user-drag: none;
}

.full-width {
  width: 100%;
}
.left-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button:focus { outline: thin dotted blue; }

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 14px;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 8px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0 0 3px 2px #0099E0;
  -moz-box-shadow: 0 0 3px 2px #0099E0;
  box-shadow: 0 0 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0 0 5px 5px #0099E0;
  -moz-box-shadow: 0 0 5px 5px #0099E0;
  box-shadow: 0 0 5px 5px #0099E0;
}

.link_button, .link_button:hover {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #FFF;
  text-decoration: none;

  background-color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 100px;
  transition: all 0.25s;
  font-size: 18px;
  outline: none;
  display: block;
  cursor: pointer;
}

.opaque {
  opacity: 1 !important;
}

.tool-tip {
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.data-grid {
  vertical-align: middle !important;
}

.centered-cell {
  vertical-align: middle !important;
  text-align: center !important;
}

.positive-cell {
  background-color: rgb(154, 252, 154) !important;
}

.negative-cell {
  background-color: rgba(255, 97, 97, 0.71) !important;
}

.edited-cell {
  background-color: rgb(255, 210, 126) !important;
}

