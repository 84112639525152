.data-grid {
  width: 100%;
  vertical-align: middle !important;
}

.cell {
  height: 40px !important;
}

.width-200 {
  width: 200px !important;
}

.top-left-align {
  text-align: left !important;
  padding: 10px !important;
  vertical-align: top !important;
}

.horizontal-sticky {
  left: 0;
  width: 300px !important;
  position: sticky;
}

.data-editor {
  height: 100% !important;
}

.nav-bar-stretch {
	flex-grow: 1;
	overflow: auto;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.ant-tabs-content-holder::-webkit-scrollbar {
  display: none;
}

.real-desktop {
	display: none;
}

.real-mobile {
	display: none;
}

.fade-in-animation {
	animation: fadeInOpacity ease-in-out 2s;
	animation-fill-mode: forwards;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

html,
body {
	font-family: "Maison-Book", sans-serif;
  line-height: 0;
}

.tooltip-desc {
	margin-top: 21px;
	display: flex;
	flex-wrap: wrap;

	.tooltip-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 11px;
		margin-right: 20px;

		p {
			margin-bottom: 0;
			margin-left: 5px;
			font-size: 14px;
		}
	}
}

/* #region CUSTOM BOOTSTRAP */
.custom-container,
.custom-container-fluid,
.custom-container-lg,
.custom-container-md,
.custom-container-sm,
.custom-container-xl {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 576px) {
	.custom-container,
	.custom-container-sm {
		max-width: 540px;
	}

	.custom-col-sm {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.custom-row-cols-sm-1 > * {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.custom-row-cols-sm-2 > * {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-row-cols-sm-3 > * {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-row-cols-sm-4 > * {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-row-cols-sm-5 > * {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.custom-row-cols-sm-6 > * {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-sm-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.custom-col-sm-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.custom-col-sm-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-sm-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-col-sm-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-col-sm-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.custom-col-sm-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-col-sm-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.custom-col-sm-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.custom-col-sm-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.custom-col-sm-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.custom-col-sm-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.custom-col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 768px) {
	.custom-container,
	.custom-container-md,
	.custom-container-sm {
		max-width: 720px;
	}

	.custom-col-md {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.custom-row-cols-md-1 > * {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.custom-row-cols-md-2 > * {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-row-cols-md-3 > * {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-row-cols-md-4 > * {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-row-cols-md-5 > * {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.custom-row-cols-md-6 > * {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-md-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.custom-col-md-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.custom-col-md-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-col-md-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-col-md-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.custom-col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-col-md-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.custom-col-md-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.custom-col-md-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.custom-col-md-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.custom-col-md-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.custom-col-md-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 992px) {
	.custom-container,
	.custom-container-lg,
	.custom-container-md,
	.custom-container-sm {
		max-width: 960px;
	}

	.custom-col-lg {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.custom-row-cols-lg-1 > * {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.custom-row-cols-lg-2 > * {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-row-cols-lg-3 > * {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-row-cols-lg-4 > * {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-row-cols-lg-5 > * {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.custom-row-cols-lg-6 > * {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-lg-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.custom-col-lg-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.custom-col-lg-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-col-lg-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-col-lg-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.custom-col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-col-lg-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.custom-col-lg-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.custom-col-lg-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.custom-col-lg-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.custom-col-lg-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.custom-col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 1200px) {
	.custom-container,
	.custom-container-lg,
	.custom-container-md,
	.custom-container-sm,
	.custom-container-xl {
		max-width: 1140px;
	}

	.custom-col-xl {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.custom-row-cols-xl-1 > * {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.custom-row-cols-xl-2 > * {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-row-cols-xl-3 > * {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-row-cols-xl-4 > * {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-row-cols-xl-5 > * {
		flex: 0 0 20%;
		max-width: 20%;
	}
	.custom-row-cols-xl-6 > * {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-xl-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.custom-col-xl-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}
	.custom-col-xl-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}
	.custom-col-xl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.custom-col-xl-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}
	.custom-col-xl-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}
	.custom-col-xl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.custom-col-xl-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}
	.custom-col-xl-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}
	.custom-col-xl-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.custom-col-xl-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}
	.custom-col-xl-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}
	.custom-col-xl-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
.custom-row {
	display: flex;
	flex-wrap: wrap;
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;
}
.no-gutters > .custom-col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}
.custom-col,
.custom-col-1,
.custom-col-2,
.custom-col-3,
.custom-col-4,
.custom-col-5,
.custom-col-6,
.custom-col-7,
.custom-col-8,
.custom-col-9,
.custom-col-10,
.custom-col-11,
.custom-col-12,
.custom-col-auto,
.custom-col-lg,
.custom-col-lg-1,
.custom-col-lg-2,
.custom-col-lg-3,
.custom-col-lg-4,
.custom-col-lg-5,
.custom-col-lg-6,
.custom-col-lg-7,
.custom-col-lg-8,
.custom-col-lg-9,
.custom-col-lg-10,
.custom-col-lg-11,
.custom-col-lg-12,
.custom-col-lg-auto,
.custom-col-md,
.custom-col-md-1,
.custom-col-md-2,
.custom-col-md-3,
.custom-col-md-4,
.custom-col-md-5,
.custom-col-md-6,
.custom-col-md-7,
.custom-col-md-8,
.custom-col-md-9,
.custom-col-md-10,
.custom-col-md-11,
.custom-col-md-12,
.custom-col-md-auto,
.custom-col-sm,
.custom-col-sm-1,
.custom-col-sm-2,
.custom-col-sm-3,
.custom-col-sm-4,
.custom-col-sm-5,
.custom-col-sm-6,
.custom-col-sm-7,
.custom-col-sm-8,
.custom-col-sm-9,
.custom-col-sm-10,
.custom-col-sm-11,
.custom-col-sm-12,
.custom-col-sm-auto,
.custom-col-xl,
.custom-col-xl-1,
.custom-col-xl-2,
.custom-col-xl-3,
.custom-col-xl-4,
.custom-col-xl-5,
.custom-col-xl-6,
.custom-col-xl-7,
.custom-col-xl-8,
.custom-col-xl-9,
.custom-col-xl-10,
.custom-col-xl-11,
.custom-col-xl-12,
.custom-col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.custom-col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}
.custom-row-cols-1 > * {
	flex: 0 0 100%;
	max-width: 100%;
}
.custom-row-cols-2 > * {
	flex: 0 0 50%;
	max-width: 50%;
}
.custom-row-cols-3 > * {
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}
.custom-row-cols-4 > * {
	flex: 0 0 25%;
	max-width: 25%;
}
.custom-row-cols-5 > * {
	flex: 0 0 20%;
	max-width: 20%;
}
.custom-row-cols-6 > * {
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}
.custom-col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}
.custom-col-1 {
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}
.custom-col-2 {
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}
.custom-col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}
.custom-col-4 {
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}
.custom-col-5 {
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
}
.custom-col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}
.custom-col-7 {
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
}
.custom-col-8 {
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}
.custom-col-9 {
	flex: 0 0 75%;
	max-width: 75%;
}
.custom-col-10 {
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
}
.custom-col-11 {
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
}
.custom-col-12 {
	flex: 0 0 100%;
	max-width: 100%;
}
.custom-offset-1 {
	margin-left: 8.3333333333%;
}

/* #endregion CUSTOM BOOTSTRAP */

/* #region SET BASE FONTS */

.active,
button.active,
.price.active,
.button-text.active {
	font-family: Maison-Bold, sans-serif;
}

.active.carousel-item {
	font-family: Maison-Book, sans-serif;
}

.bold {
	font-family: Maison-Bold, sans-serif;
}

.medium {
  font-family: Maison-Medium, sans-serif;
}

.large-text {
  font-size: 25px;
}

.bold-white {
  font-family: Maison-Bold, sans-serif;
  color: white;
}

.red-button-next,
.red-button,
.step-title,
h4,
.mobile-red-button {
	font-family: Maison-Bold, sans-serif;
	color: #415053;
}
.hidden-desc-text {
	font-family: Maison-Book, sans-serif;
}

h3 {
	font-size: 21px;
}

.price-wrapper > span {
	font-size: 21px;
	font-weight: bold;
}

.bottom-bar-bold {
	font-size: 21px;
	font-weight: bold;
}

h2 {
	font-size: 2rem;
}

h4 {
	font-size: 1.25rem;
}

/* #endregion SET BASE FONTS */

/* #region TEXT STYLING */

.step-title {
	margin-top: 40px;
}

.option-title {
	margin-bottom: 0;
	margin-top: 35px;
	font-size: 19px;
}

.greyed-out {
	color: #bababa;
	font-size: 13px;
	opacity: 0.6;
}

.capitilized {
	margin-bottom: 0;
	width: 100%;
	text-transform: capitalize;
	font-weight: 500;
	font-size: 14px;
}

.hidden-desc-text {
	margin-top: 18px;
	margin-bottom: 0;
	font-weight: 500;
	font-size: 14px;
}

.floor-tooltip {
	margin-top: 26px;

	p {
		font-size: 14px;
	}

	.what-is-this {
		margin-bottom: 0;
		cursor: pointer;
		text-decoration: underline;
		width: fit-content;
	}

	#linoleum-tip,
	#vinyl-tip,
	#linoleum-tip-living-room,
	#carpet-tip-living-room,
	#carpet-tip-bedroom,
	#linoleum-tip-bedroom {
		margin-top: 7px;
		margin-bottom: 0;
	}

	.tooltip-desc {
		margin-top: 21px;
		display: flex;
		flex-wrap: wrap;

		.tooltip-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 38%;
			margin-bottom: 11px;

			p {
				margin-bottom: 0;
				margin-left: 5px;
				font-size: 14px;
			}
		}
	}
}

.bath-desc-wrapper {
	.hidden-desc-text:nth-child(2),
	.hidden-desc-text:nth-child(3),
	.hidden-desc-text:nth-child(4) {
		margin-top: 10px;
	}
}

.description-wrapper {
	margin-bottom: 25px;
	text-transform: capitalize;

	h4 {
		margin-bottom: 16px;
	}
}

.terms-policy {
	margin-bottom: 41px;
}

.no-transform {
	text-transform: none;
}

.base-price {
	p {
		font-size: 22px;
		font-family: Maison-Bold, sans-serif;
	}
}

.fine-print {
	margin-top: 15px;
	color: rgba(46, 49, 49, 0.4);
}

.show-details {
	cursor: pointer;
	color: var(--accent-color);
}

/* #endregion TEXT STYLING */

/* #region BUTTON STYLING */
.red-button-next,
.red-button,
.mobile-red-button {
	background-color: #000000;
	border: none;
	padding: 9px 1px;
	border-radius: 100px;
	color: #fff;
	transition: all 0.25s;
	font-size: 18px;
	outline: none;
}

.black-btn {
  margin-bottom: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  border-radius: 35px;
  border: none;
  outline: none;
  font-size: 17.5px;
  font-weight: bold;
  transition: all .35s;
  background-color: #000;
  color: #FFF;
}

.yellow-btn.link, .black-btn.link, .white-btn.link, .green-btn.link {
  padding-left: 40px;
  padding-right: 40px;
  text-decoration: none;
}

.yellow-btn.link:hover, .white-btn.link:hover, .green-btn.link:hover, .green-btn.link {
  color: #000;
}

.black-btn.link:hover {
  color: #FFF;
}


.button-primary {
	background-color: #000000;
	border: none;
	padding: 10px 20px;
	border-radius: 100px;
	color: #fff;
	transition: all 0.25s;
	font-size: 18px;
	outline: none;
	cursor: pointer;
}

.button-secondary {
	background-color: transparent;
	border: 1px solid black;
  padding: 10px 20px;
	border-radius: 100px;
	color: black;
	transition: all 0.25s;
	font-size: 18px;
	outline: none;
	cursor: pointer;
}

.mobile-red-button {
	width: 50%;
}

.next-button-wrapper {
	margin: auto;
	max-width: 380px;
	width: 100%;
}

.red-button-next {
	width: 100%;
	margin-top: 3px;
}

.red-button {
	margin-top: 33px;
	width: 100%;
}

.red-button-next:focus,
.red-button:focus {
	outline: none;
}

.red-button-next:hover,
.red-button:hover {
	background-color: #000000;
}

.floorplan-buttons {
	div {
		width: 100%;
	}
}

/* #endregion BUTTON STYLING */

/* #region BASE STRUCTURE */

#top-anchor {
	position: absolute;
	top: -260px;
	left: 0;
	display: none;
}

.app-body-wrapper {
	height: calc(100vh - 130px);

	.row {
		height: 100%;

		.current-image-wrapper {
			padding-left: 0;
			//padding-right: 30px;
			height: 100%;
			overflow: hidden;
			width: calc(100% - 489px);

			.exterior-svg {
				background-color: #f5f6f6;
			}
		}

		.current-description-wrapper {
			height: 100%;
			overflow-y: auto;
			padding: 20px 52px 20px 0;
			width: 489px;
		}
	}
}
//
//.current-description-wrapper::-webkit-scrollbar {
//  height:unset;
//  width: unset;
//}

.app-body-wrapper-full-height {
	height: calc(100vh - 65px);

	.row {
		height: 100%;

		.current-image-wrapper {
			padding-left: 0;
			//padding-right: 30px;
			height: 100%;
			overflow: hidden;
			width: calc(100% - 489px);

			.exterior-svg {
				background-color: #f5f6f6;
			}
		}

		.current-description-wrapper {
			height: 100%;
			overflow-y: auto;
			padding: 20px 52px 20px 0;
			width: 489px;
		}
	}
}

.submit-form-wrapper {
	height: calc(100vh - 40px);
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
}

.userLocation-wrapper {
	padding: 15px;
	border: 1px solid rgba(46, 49, 49, 0.4);
	border-radius: 5px;

	input {
		border: none;
		outline: none;
		width: 80%;
	}
}

/* #endregion BASE STRUCTURE */

/* #region DESKTOP/MOBILE CLASSES */

.hidden {
	display: none;
}

.mobile-appear {
	display: none;
}

.desktop-hidden {
	display: none;
}

.mobile-logo-submit {
	display: none;
}

.submit-image-mobile {
	display: none;
}

/* #endregion DESKTOP/MOBILE CLASSES */

/* #region UNDERLINE STYLING */

.one-line > .show-details {
	color: var(--accent-color);
	text-decoration: underline;
}

.option-underline {
	width: 100%;
	border-bottom: 2px solid rgba(232, 232, 232, 1);
	margin-top: 37px;
}

.result-underline {
	width: 100%;
	border-bottom: 2px solid rgba(232, 232, 232, 1);
	margin: 33px 0;
}

/* #endregion UNDERLINE STYLING */

/* #region FLOORPLAN SPECIFIC */

.floorplan-app-wrapper .nav-row > div > img,
.floorplan-app-wrapper .nav-row button {
	display: none;
}

.floorplan-app-wrapper .nav-bar {
	box-shadow: none;
}

.floorplan-app-wrapper {
	.app-body-wrapper {
		.row {
			.current-image-wrapper {
				background-color: #fff;
				padding: 0;
			}
		}
	}

	.mobile-back-button {
		pointer-events: none;
		opacity: 0.7;
	}
}

.floorplan-back-button {
	pointer-events: none;
	opacity: 0.7;
}
.iframe-container {
	width: 100%;
	height: 100%;
	align-items: center;
	position: relative;
	overflow: hidden;
}

.iframe-div {
	height: 100%;
	width: 300%;
	margin-left: -100%;
	pointer-events: none;
}

/* #endregion FLOORPLAN SPECIFIC */

/* #region CUSTOM COLOR */
.custom-color-outline {
	opacity: 0.8;
}

/* #endregion CUSTOM COLOR */

@media (max-width: 1400px) {
	.floorplan-app-wrapper {
		.app-body-wrapper {
			.row {
				.current-image-wrapper {
					padding: 67px;
				}
			}
		}
	}
}

@media (max-width: 1025px) {
	/* #region HIDDEN */

	/* #endregion HIDDEN */

	/* #region DISPLAYED */

	.desktop-hidden {
		display: block;
	}

	.real-mobile {
		display: block;
	}

	.mobile-description-wrapper {
		display: flex;
	}

	.mobile-hidden {
		display: none;
	}

	.mobile-nav-bar {
		display: flex;
		flex-direction: column;
	}

	.mobile-logo {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mobile-logo-submit {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		width: 100%;
		z-index: 10;
		top: 0;
	}

	/* #endregion DISPLAYED */

	/* #region FONT CHANGES */

	h2 {
		font-size: 1.55rem;
	}

	h3,
	.price-wrapper > span {
		font-size: 1.35rem;
	}

	h4 {
		font-size: 1.25rem !important;
	}

	.unit-description,
	.price-wrapper,
	.submit-form-wrapper {
		p {
			font-size: 0.85rem;
		}
	}

	/* #endregion FONT CHANGES */

	/* #region TEXT STYLING */

	.step-title {
		margin-top: 0;
		margin-bottom: 33px;
		text-align: center;
	}

	.option-title {
		margin-top: 39px;
	}

	.result-wrapper {
		.red-button {
			margin-bottom: 40px;
		}
	}

	.back-button-wrapper {
		display: flex;
		justify-content: center;

		.back-button {
			outline: none;
			border: none;
			background-color: transparent;
			margin-top: 21px;
			margin-bottom: 28px;
		}
	}

	.rendered-image-wrapper {
		.carousel {
			.carousel-indicators {
				bottom: 15px;
			}
		}
	}

	/* #endregion TEXT STYLING */

	/* #region BASE STRUCTURE */

	.submit-form-wrapper {
		height: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;

		.submit-logo {
			display: none;
		}
	}

	.app-body-wrapper {
		height: calc(100vh - 150px);

		.row {
			.current-image-wrapper {
				padding-right: 0;
				height: 55%;
				width: 100%;
			}

			.current-description-wrapper {
				padding-left: 15px;
				padding-right: 15px;
				width: 100%;
				overflow: visible;
			}
		}
	}

	.app-body-wrapper-full-height {
		height: 100vh;

		.row {
			.current-image-wrapper {
				padding-right: 0;
				height: 55%;
				width: 100%;
			}

			.current-description-wrapper {
				// height: 60%;
				padding-left: 15px;
				padding-right: 15px;
				width: 100%;
				overflow: visible;
			}
		}
	}

	.bottom-bar {
		padding: 8px 30px;

		.row {
			margin-left: 0;
			margin-right: 0;
		}

		.bottom-wrapper {
			width: 100%;

			.bottom-description {
				width: 100%;
				margin-left: 0;

				.bottom-bar-description {
					width: 100%;

					.unit-wrapper {
						h3 {
							font-size: 19px;
						}

						p {
							font-size: 14px;
						}

						.dollar-feet {
							display: none;
						}

						.small-desc {
							margin-right: 0;
						}
					}

					.price-wrapper {
						span {
							font-size: 19px;
							font-weight: bold;
						}
						p {
							font-size: 14px;
						}
					}
				}

				.bottom-bar-description > p {
					display: none;
				}
			}
		}
	}

	/* #endregion BASE STRUCTURE */

	/* #region BUTTONS */

	.image-text-button-wrapper {
		div {
			.text-wrapper {
				padding-left: 14px;
				padding-right: 33px;
			}
		}
	}

	.button-layout {
		.pill-button-wrapper:nth-child(1) {
			margin-right: 16px;
		}
	}

	/* #endregion BUTTONS */

	/* #region MODAL */

	.modal-content {
		.modal-header {
			.step-bar {
				width: 100%;

				p {
					font-size: 16px;
				}


				p:nth-child(1) {
					margin-right: 15px;
				}
			}
		}

		.modal-body {
			p {
				font-size: 16px;
			}
		}
	}

	/* #endregion MODAL */
}

@media (max-width: 768px) {
	/* #region HIDDEN */



  .autocomplete-dropdown-container {
    width: 100%;
  }

  .address-widget {
    width: 100%;
    padding: 4px;
    border: 1px solid;
    margin-bottom: 6px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
  }

	.mobile-hidden {
		display: none;
	}

	.mobile-appear {
		display: block;
	}

	.mobile-description-wrapper {
		display: flex;
	}

	.bottom-bar {
		padding: 8px 5px 8px 30px;
	}

	.bottom-bar-result {
		display: none;
	}

	.rendered-image-wrapper {
		.carousel {
			.carousel-control-prev,
			.carousel-control-next {
				opacity: 1;
				z-index: 1000;

				.right-arrow {
					position: absolute;
					right: 0;
				}

				.left-arrow {
					position: absolute;
					left: 0;
				}
			}
		}
	}

	.submit-image-desktop {
		display: none;
	}

	/* #endregion HIDDEN */

	/* #region BASE STRUCTURE */

	#top-anchor {
		display: block;
	}

	.mobile-logo {
		/*         position: fixed;
        z-index: 3; */
		width: 100%;
		background-color: #fff;
	}

	.app-body-wrapper {
		height: calc(100vh - 150px);

		.row {
			height: unset;
			.current-image-wrapper {
				padding-right: 0;
				width: 100%;
				/*  position: fixed;
                top: 80px; */
				z-index: 3;
			}

			.current-description-wrapper {
				padding: 15px 15px 18px;
				width: 100%;
				margin-top: 0;
				overflow-y: visible;
				z-index: 0;
			}
		}
	}

	.app-body-wrapper-result {
		height: calc(100vh - 50px);
	}

	.bottom-bar {
		.bottom-wrapper {
			.bottom-description {
				.bottom-bar-description {
					justify-content: space-between;

					.unit-wrapper {
						.unit-description {
							.square-feet {
								margin-right: 15px;
							}
						}
					}
				}
			}
		}
	}

	.app-body-wrapper-full-height {
		height: 100%;

		.row {
			.current-image-wrapper {
				padding-right: 0;
				height: 365px;
				width: 100%;
			}

			.current-description-wrapper {
				height: 100%;
				padding: 15px 15px 18px;
				width: 100%;
				margin-top: 0;
				overflow-y: initial;
				z-index: 0;
			}
		}
	}

	/* #endregion BASE STRUCTURE */

	/* #region TEXT STYLING/SPACING */
	.result-wrapper {
		.red-button {
			margin-bottom: 0;
		}
	}

	.submit-image-mobile {
		display: block;
	}

	.floor-tooltip {
		.what-is-this {
			margin-bottom: 5px;
		}

		.tooltip-desc {
			.tooltip-item {
				width: 50%;
			}
		}
	}

	.back-button-wrapper {
		padding-bottom: 66px;
	}

	form {
		.option-title {
			margin-top: 20px;
		}
	}

	.step-title {
		margin-bottom: 25px;
	}
	/* #endregion TEXT STYLING/SPACING */

	/* #region MODAL */

	.modal-dialog {
		.modal-content {
			.modal-body {
				.modal-text {
					margin: 0 0 0 -5px;
				}
			}
		}
	}

	/* #endregion MODAL */

	/* #region FLOORPLAN */

	.floorplan-app-wrapper {
		.app-body-wrapper {
			.row {
				.current-description-wrapper {
					height: fit-content;
				}

				.current-image-wrapper {
					padding: 0;
				}
			}
		}
	}

	/* #endregion FLOORPLAN */
}

@media (max-width: 415px) {
	.search-location-input {
		min-width: calc(100vw - 60px);
		width: 100%;
	}
}

@media (max-width: 321px) {
	/* #region FONT SIZES */

	.unit-description,
	.price-wrapper {
		p {
			font-size: 0.75rem;
		}
	}

	.step-bar {
		p {
			font-size: 0.8rem;

			span {
				img {
					margin-right: 4px;
				}
			}
		}
	}

	.image-text-button-wrapper {
		div {
			.button-text {
				font-size: 14px;
			}

			.price {
				font-size: 14px;
			}
		}
	}

	.text-button-wrapper {
		div {
			.button-text {
				font-size: 14px;
			}

			.price {
				font-size: 14px;
			}
		}
	}

	/* #endregion FONT SIZES */

	/* #region BASE STRUCTURE */

	.bottom-bar {
		.bottom-wrapper {
			.bottom-description {
				.bottom-bar-description {
					.unit-wrapper {
						.unit-description {
							.square-feet {
								margin-right: 7.5px;
							}
						}
					}
				}
			}
		}
	}

	.app-body-wrapper {
		height: calc(100vh - 150px);

		.row {
			.current-image-wrapper {
				padding-right: 0;
				max-height: 180px;
				min-height: 180px;
				width: 100%;
			}

			.current-description-wrapper {
				padding: 15px 15px 18px 15px;
				width: 100%;
			}
		}
	}

	.app-body-wrapper-result {
		height: calc(100vh - 50px);
	}

	/* #endregion BASE STRUCTURE */

	/* #region TEXT STYLING/SPACING */

	.floor-tooltip {
		#linoleum-tip,
		#vinyl-tip,
		#linoleum-tip-living-room,
		#carpet-tip-living-room,
		#carpet-tip-bedroom,
		#linoleum-tip-bedroom {
			margin-top: 7px;
			margin-bottom: 0;
		}

		.tooltip-desc {
			margin-top: 21px;

			.tooltip-item {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 50%;
				margin-bottom: 11px;

				p {
					margin-bottom: 0;
					margin-left: 5px;
					font-size: 14px;
				}
			}
		}
	}

	/* #endregion TEXT STYLING/SPACING */

	/* #region BUTTONS */

	.button-layout {
		.pill-button-wrapper:nth-child(1) {
			margin-right: 14px;
		}
	}

	.button-layout {
		.color-button-wrapper {
			button {
				margin-right: 15px;
			}
		}
	}

	/* #endregion BUTTONS */

	/* #region MODAL */

	.modal-dialog {
		.modal-content {
			.modal-body {
				.modal-text {
					.one-line {
						width: 95%;
					}
				}
			}
			.modal-header {
				.step-bar {
					p:nth-child(1) {
						margin-right: 12px !important;
					}

					p {
						font-size: 11px !important;
					}
				}
			}
		}
	}

	/* #endregion MODAL */
}

@media (min-width: 1025px) {
	.real-mobile {
		display: none;
	}

	.real-desktop {
		display: block;
	}
}
