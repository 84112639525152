/* #region KEYFRAMES */

@keyframes unhide {
    0% {
        opacity: 0;
        display: none;
    }

    55% {
        opacity: 0;
        display: flex;
    }

    100% {
        opacity: 1;
    }
}

@keyframes selectedButton {
    0% {
        background-color: #FFF;
    }

    80% {
        background-color: #FFEE7E;
    }

    100% {
        background-color: #9EA7A9;
    }
}



/* #endregion KEYFRAMES */


/* #region BUTTONS */


.yellow-btn,
.black-btn,
.white-btn {

    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    border-radius: 35px;
    border: none;
    outline: none;
    font-size: 17.5px;
    font-weight: bold;
    transition: all .35s;

}

.yellow-btn {
    background-color: var(--accent-color);
    color: #000;
}

.yellow-btn:hover, .yellow-btn:focus {
    background-color: #ADD7A4;
}

.yellow-btn.link, .black-btn.link, .white-btn.link {
    padding-left: 40px;
    padding-right: 40px;
    text-decoration: none;
}

.yellow-btn.link:hover, .white-btn.link:hover {
    color: #000;
}

.black-btn.link:hover {
    color: #FFF;
}

.black-btn {
    background-color: #000;
    color: #FFF;
}

.black-btn:hover, .black-btn:focus {
    background-color: #333333;
}

.white-btn {
    background-color: #FFF;
    color: #000;
    border: 1.5px solid #000;
}

.white-btn:hover, .white-btn:focus {
    background-color: #D8DCDD;
    border: 1.5px solid #D8DCDD;
}

.half-width {
    width: 181px;
}

/* #endregion BUTTONS */


/* #region FORMS COMPONENT */

.form-wrapper-step-zero {
    height: 70%;
}

.step-wrapper-full-height {
    height: 100%;
    width: 100%;
}

.step-wrapper {
    height: fit-content;
    width: 100%;
    position: relative;
}

.form-wrapper {

    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .input-wrapper {
        width: 100%;
        max-width: 335px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .error-message {
            margin-top: -15px;
            padding-left: 10px;
        }
    }

    .question-wrapper {
        width: 100%;

        .question {
            width: 100%;
        }
    }

    .form-button-wrapper {

        input {
            display: none;
        }

        .form-button {

            width: 335px;
            background-color: #FFF;
            border: 2px solid #C4C4C4;
            border-radius: 35px;
            outline: none;
            padding: 12px 37px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 10px;
            transition: .25s all;

            span {
                pointer-events: none;
            }
        }

        .choosen.form-button {
            background-color: #82C275;
        }

    }

    .text-input-wrapper {
        width: 100%;

        input {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
            outline: none;
            border: none;
            border-bottom: 1.5px solid rgba(0, 0, 0, 0.45);
            padding-left: 10px;
            border-radius: 0;
        }
    }

    #button-form-submit {
        display: none;
    }


}

.form-button:hover {
  //border-color: var(--accent-color) !important;
}


/* #endregion FORMS COMPONENT */

/* #region STEPPER COMPONENT */

/* Make the container of nav stepper relative to align progress bar */
.makeStyles-root-1, .jss1 {
    position: relative !important;
}

/* This is the starting point, this is used for question one, the 88 px comes from 24px of padding each side and the 20px for the first and last circle on the stepper */
.progress-bar {
    border-top: 2px solid #3E5054;
    transform: scaleX(0);
    position: absolute;
    bottom: 48%;
    width: calc(100% - 88px);
    left: 44px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: 0 50%;
    background-color: transparent !important;
}

/* For every step, make changes to the progress bar */
.step-one-substep-one {
    .progress-bar {
        transform: scaleX(.135);
    }
}

.step-one-substep-two, .step-two {
    .progress-bar {
        transform: scaleX(.305);
    }
}

.step-two-substep-one {
    .progress-bar {
        transform: scaleX(.66);
    }
}

.step-three {
    .progress-bar {
        transform: scaleX(.66);
    }
}

.step-contact {
    .progress-bar {
        transform: scaleX(1);
    }
}

.MuiTablePagination-input {
  display: none !important;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.Component-paginationToolbar-2 {
  padding: 0.5rem 0 !important;
}

.MTablePaginationInner-root-14 {
  span {
    margin-left: 0.5rem;
    button {
      span {
        margin: 0;
      }
    }
  }
}
.MTableToolbar-root-5 {
  padding: 0 !important;
  height: 2rem !important;
  min-height: 2rem !important;
}


.MuiStepConnector-lineHorizontal {
    border-top-width: 2px !important;
}

.MuiStepLabel-iconContainer {
    padding-right: 0 !important;
}

.MuiStepLabel-labelContainer {
    display: none !important;
}

.MuiStep-horizontal {

    padding: 0 !important;

    .MuiStepLabel-root {

        .current-step {
            display: block;
            position: relative;
            z-index: 2;
        }

        .next-step {
            display: none;
            position: relative;
            z-index: 2;
        }

        .completed-step {
            display: none;
            position: relative;
            z-index: 2;
        }

    }

    .Mui-disabled {

        .current-step {
            display: none;
        }

        .next-step {
            display: block;
        }

        .completed-step {
            display: none;
        }

    }

}

.MuiStep-completed {
    .MuiStepLabel-root {

        .current-step {
            display: none;
        }

        .next-step {
            display: none;
        }

        .completed-step {
            display: block;
        }

    }
}

/* #endregion STEPPER COMPONENT */

/* #region STEP ZERO / GOOGLE SEARCH */

.search-location-input {
    min-width: 300px;
    max-width: 375px;
    width: 375px;

    .intake-input-icons-wrapper {

        margin-bottom: 10px;
        position: relative;
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);

        #address-input {
            width: 100%;
            border: none;
           /*  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1); */
            height: 47px;
            outline: none;
            padding: 10px 35px 5px 35px;
            border-bottom: 2px solid transparent;

        }

        #next-arrow {
            position: absolute;
            top: 13px;
            right: 10px;
            z-index: 2;
            cursor: pointer;
        }

        #verified-icon {
            position: absolute;
            top: 14px;
            left: 10px;
            z-index: 2;
            border-radius: 30px;
        }


    }

    .error-message {
        margin-top: 10px !important;
    }


}

/* #endregion STEP ZERO / GOOGLE SEARCH */

/* #region TOP BAR */

.top-bar {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 44px;
    margin-bottom: 24px;

    .back-button {
        position: absolute;
        left: 0;
        top: 3px;
        background-color: transparent;
        outline: none;
        border: none;

    span {
        svg {
            margin-right: 5px;
            margin-top: -2.55px;
            }
        }
    }

    img {
        margin-top: -20px;
    }
}

/* TOP BAR MOBILE */
.top-bar.mobile-only {
    display: none;
    margin: 0;
}


/* #endregion TOP BAR */

/* #region GLOBAL CLASSES */
//html, body {
//    height: 100%;
//    overflow: hidden;
//}
//
//p {
//    font-size: 18px;
//}
//
//h2 {
//    font-weight: 500;
//    font-family: 'Maison-Medium';
//    font-size: 48px;
//}
//
//h3 {
//    font-weight: 500;
//    font-family: 'Maison-Medium';
//    font-size: 18px;
//}
//
//button {
//    font-weight: 500;
//    font-family: 'Maison-Medium';
//}

.question {
    font-size:20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.get-started, .confirmation-title {
    margin-bottom: 0;
    margin-top: 20px;
}

.full-height {
    height: 100vh;
}

.hidden {
    display: none;
    transition: .25s all;
}

.current-image {
    padding-left: 0;

    .image-wrapper {
        height: 100%;
        width: 100%;
    }
}

.current-step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.error-message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    p {
        margin-bottom: 0;
        margin-left: 5px;
        color: #F96353;
        font-size: 16px;
    }
}

.error-input {
    border-bottom: 2px solid #F96353 !important;
}

.animation-wrapper {
    position: absolute;
    top: 17%;
    opacity: 1;
    transition: .25s all;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 40px;
    text-align: center;

    .animated-div {
        width: 300px;
        text-align: center;
        margin-top: 50px;

        .title {
            color: #E36E61;
            font-size: 18px;
            font-family: "Maison-Medium", sans-serif;
        }
    }


}

.animation-wrapper-hidden {
    opacity: 0;
}

.fading-enter {
    opacity: 0;
}

.fading-enter-active {
    opacity: .5;
    transition: opacity 300ms ease-in;
}

.fading-enter-done {
    opacity: 1;
}

.fading-exit {
    opacity: 1;

}

.fading-exit-active {
    opacity: 0;
}

.fading-exit-done {
    opacity: 0;
}

.close-button {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    background-color: transparent;
    border: none;
    outline: none;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2Fclose.png?alt=media&token=592957aa-382a-4212-93b3-b6557d3726df);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: -5px;
}

/* #endregion GLOBAL CLASSES */

/* #region CONFIRMATION PAGE */

.confirmation-title-wrapper {
    max-width: 335px;
    width: 100%;

    .confirmation-title {
        width: 100%;
        font-size: 32px;
    }
}

.confirmation-buttons {

    display: flex;
    flex-direction: column;
    justify-content: center;

    .yellow-btn {
        margin-bottom: 18px;
    }
}

.confirmation-text {
    width: 100%;
    max-width: 335px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.confirmation {
    .current-step-icon {
        margin-bottom: -15px;
        margin-top: 100px;
    }
}


/* #endregion CONFIRMATION PAGE */

/* #region STEP ONE SUBSTEP ONE */

.unhide {
    animation: unhide 2s linear forwards !important;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 22;
    width: 100%;
}

.skip-button {
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    color: #8B9FE5;
    top: 250px;
}

/* #endregion STEP ONE SUBSTEP ONE */


/* #region MEDIA QUERIES */

@media (max-height:650px) {
    #root {
        .App {
            .container-fluid {
                overflow-y: scroll !important;
            }
        }
    }

    .form-wrapper {
        margin-bottom: 20px;
    }
}

@media (max-width:992px) {
    .animation-wrapper {
        top: 30%;
    }

    .current-image {
        padding-right: 0 !important;
    }
}

@media (max-width: 768px) {
    .current-image {
        display: none;
    }

    .current-image.step-zero, .current-image.confirmation, .current-image.step-no-contact {
        display: block;
        padding-right: 0;
        height: 40%;

        .top-bar.mobile-only {
            display: block;
            position: absolute;
            top: 10px;
        }
    }

    .current-step.step-zero {

        height: 60%;
        .current-step-icon {
            display: none;
        }

        .top-bar {
            display: none;
        }
    }

    .current-step.confirmation, .current-step.step-no-contact {
        height: 60%;

        .top-bar {
            display: none;
        }

        .current-step-icon {
            margin-top: 15px;
        }
    }

    .makeStyles-root-1 {

        width: 100% !important;
    }

    #root {
        overflow-y: scroll !important;
        height: 100%;
    }
}

@media (max-width: 415px) {
    .form-wrapper {
        width: 100%;
        padding-bottom: 44px;

        .question-wrapper {
            width: calc(100vw - 30px);
            margin: 0 auto;

            .question {
                font-size: 18px;
                margin-top: 5px;
            }
        }

        .input-wrapper {

            max-width: 100%;

            .form-button-wrapper {

                .form-button {
                    width: calc(100vw - 30px);
                }

            }

        }
    }

    .search-location-input {
        min-width: 0;
        max-width: 98vw;
        width: calc(100vw - 40px);
    }

    .animation-wrapper {
        .animated-div {
            margin-top: 75px;
        }
    }

    .step-wrapper-full-height {
        margin-top: 25px;
    }

    .confirmation {
        .current-step-icon {
            margin-top: 30px;
        }

    }

    .get-started {
        font-size: 30px;
        margin-top: 0;
    }

    .form-wrapper-step-zero {
        justify-content: flex-start;
    }

    .jss1, .makeStyles-root-1 {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .top-bar {
        margin-top: 30px;
        margin-bottom: 15px;
    }

}

@media (max-width: 330px ){

    .animation-wrapper {
        top: 35%;
    }

    .form-wrapper {
        .input-wrapper {
            .form-button-wrapper {
                .form-button {
                    padding: 15px 25px;
                }
            }
        }
    }

    .form-wrapper {
        .question-wrapper {
            .question {
                font-size: 16px;
            }

        }
    }

    .confirmation-title-wrapper {
        .confirmation-title {
            font-size: 30px;
        }
    }

    .confirmation-text {
        font-size: 16px;
    }

    .yellow-btn.link, .black-btn.link, .white-btn.link {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
    }

}

/* #endregion MEDIA QUERIES */
