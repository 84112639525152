/*#region NAV BARS */
:root {
	--accent-color: #82c275;
	--accent-color-active: #e6f3e3;
}

.autocomplete-root input {
  width: 100%;
  margin: auto;
  font-size: large;
  display: block;
  padding: 15px 15px 15px 25px !important;
  border-radius: 35px;
  margin-right: 15px;
  outline: none !important;
  border: none !important;
  margin-left: 0;
  margin-bottom: 15px;
  text-align: left;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
}

.autocomplete-dropdown-container {
  width: 80%;

}

.text-link {
  color:var(--accent-color);
  text-decoration: underline;
}

.address-widget {
  width: 100%;
  padding: 6px;
  border: 1px solid;
  margin-bottom: 6px;
  border-radius: 10px;
  font-size: 17px;
  cursor: pointer;
}

.formik-input {
  border: 1px solid #ccc;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  background: 0 0;
}

.nav-bar {
	-webkit-box-shadow: inset 0 -2.5px 0 0 rgba(232, 232, 232, 1);
	-moz-box-shadow: inset 0 -2.5px 0 0 rgba(232, 232, 232, 1);
	box-shadow: inset 0 -2.5px 0 0 rgba(232, 232, 232, 1);
	position: relative;
	z-index: 5;

	.row {
		height: 65px;

		.col-sm-8 {
			max-width: 1200px;
		}

		.col-sm-2 {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			button {
				background-color: transparent;
				border: none;
				outline: none;
				height: 100%;
			}

			button[data-clicked="false"] {
				color: grey;
				opacity: 0.5;
			}

			.active {
				border-bottom: 4px solid #f3887c;
				padding-top: 5px;
			}
		}
	}
}

.nav-bar-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	min-width: fit-content;

	button {
		background-color: transparent;
		border: none;
		outline: none;
		height: 100%;
	}

	button:active {
		color: black;
	}

	button[data-clicked="false"] {
		color: grey;
		opacity: 0.5;
	}

	.active {
		border-bottom: 3px solid #000000;
		padding-top: 5px;
	}
}

.mobile-nav-bar {
	display: none;
	//height: 35px;
	padding-right: 0 !important;
	padding-left: 0 !important;
	padding-top: 7px;
	background-color: #fff;
	width: 100%;

	.mobile-nav-bar-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		overflow: auto;
		white-space: nowrap;

		button {
			background-color: transparent;
			border: none;
			outline: none;
			height: 100%;
			font-size: 16px;
			font-weight: 500;
			z-index: 5;
			border-bottom: 4px solid transparent;
			padding: 0;
			margin-right: 10px;
			margin-left: 10px;
		}

		button[data-clicked="false"] {
			color: grey;
			opacity: 0.5;
		}

		.active {
			border-bottom: 4px solid black;
			color: black;
		}

		.small-space {
			min-width: 5px;
			min-height: 5px;
		}
	}

	#nav-link-exterior {
		margin-left: 30px;
	}
}

.bottom-bar {
	background-color: #fff;
	padding-top: 4px;
	margin-top: 0;
	color: #415053;
	-webkit-box-shadow: inset 0 2px 0 0 rgba(232, 232, 232, 1);
	-moz-box-shadow: inset 0 2px 0 0 rgba(232, 232, 232, 1);
	box-shadow: inset 0 2px 0 0 rgba(232, 232, 232, 1);
	position: fixed;
	bottom: 0;
	z-index: 20;
	height: 65px;

	.bottom-wrapper {
		display: flex;
		width: calc(100% - 489px);

		.bottom-description {
			width: 100%;
			margin-left: 4.333333%;

			.bottom-bar-description {
				width: 100%;
				padding-right: 30px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.bottom-bar-seperator {
					height: 40px;
					border-right: 2px solid #e8e8e8;
				}

				.back-button {
					outline: none;
					padding-left: 20px;
					padding-right: 20px;
					color: #415053;
					background-color: transparent;
					font-family: "Maison-Bold", sans-serif;
				}

        .back-button::before {
          content: "< ";
        }

				.mobile-back-button {
					border: none;
					outline: none;
					color: #000000;
					font-weight: bold;
					background-color: transparent;
					height: 45px;
					width: 45%;
				}

        .mobile-back-button::before {
          content: "< ";
        }



				.price-wrapper {
					text-align: right;
					min-width: 82px;

					p {
						margin-bottom: -3px;
					}

					span {
						margin-bottom: 0;
					}
				}

				.unit-wrapper {
					width: fit-content;

					h3 {
						margin-bottom: 0;
					}

					.unit-description {
						display: flex;
						justify-content: space-between;

						p {
							margin-bottom: 0;
						}

						.square-feet {
							margin-right: 25px;
						}

						.small-desc {
							margin-right: 25px;
						}
					}
				}
			}
		}
	}
}

.mobile-description-wrapper {
	display: none;
	justify-content: space-between;
	margin-top: 40px;
	padding-bottom: 20px;
	border-top: 2px solid #e8e8e8;
	padding-top: 10px;

	.unit-wrapper {
		width: fit-content;

		.unit-description {
			display: flex;

			.square-feet {
				margin-right: 5px;
			}
		}
	}

	.price-wrapper {
		width: 100px;

		p {
			margin-bottom: -2px;
		}
	}

	.bottom-bar-seperator {
		height: 40px;
		margin-bottom: 15px;
		border-right: 2px solid #e8e8e8;
		width: 0;
	}

	h3,
	span {
		font-size: 21px !important;
	}

	p {
		margin-bottom: 0;
	}
}

/*#endregion NAV BARS */

/*#region CAROUSELS */
.rendered-image-wrapper {
  width:100%;
  height:100%;
  position: absolute;
  padding: inherit;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	.carousel {
		height: 100%;

		.carousel-indicators {
			margin-bottom: 0;
			bottom: 35px;

			li {
				background-color: #415053;
				margin-right: 5.5px;
				margin-left: 5.5px;
				border-bottom: none;
				border-top: none;
				border-radius: 30px;
			}
		}

		.carousel-control-next,
		.carousel-control-prev {
			opacity: 1;
			z-index: 1000;

			.right-arrow {
				position: absolute;
				right: 52px;
			}

			.left-arrow {
				position: absolute;
				left: 52px;
			}
		}

		.carousel-inner {
			height: 100%;

			.carousel-item {
				height: 100%;
				width: 100%;
				img {
					object-fit: cover;
					display: block;
				}
			}
		}
	}
	img {
		width: 100%;
	}
}

/*#endregion CAROUSELS */
.rendering-image-caption {
	position: absolute;
	bottom: 45px;
	margin: auto;
	text-align: center;
	max-width: 90%;
	width: fit-content;
	opacity: 0.7;
	transition: opacity ease-in 0.25s;
	z-index: 100;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
	border-radius: 20px;
}

.current-image-wrapper:hover .rendering-image-caption {
	opacity: 1;
}

.gallery-grid {
	display: grid;
	grid-template-columns: repeat(9, 1fr);
	grid-gap: 8px;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	max-height: 60vh;

	img {
		width: 100%;
		border: 5px solid #fff;
	}
}

.gallery-grid div {
	position: relative;
	cursor: pointer;
	&:before,
	&:after {
		transition: 0.3s opacity ease;
		opacity: 0;
	}
	&:after {
		content: "\02194";
		font-size: 80px;
		position: absolute;
		transform: translate3d(-50%, -50%, 0) rotate(-45deg);
		color: #fff;
		left: 50%;
		top: 50%;
		display: block;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 4px;
		left: 0;
		right: 0;
		background: rgba(#222, 0.5);
		display: block;
	}
	&:hover {
		&:before,
		&:after {
			opacity: 1;
			transition: 0.3s opacity ease;
		}
	}
}

/* #region BUTTONS */

.pill-button-wrapper {
	width: 47%;

	input {
		display: none;
	}

	button {
		min-height: 46px;
		border-radius: 10px;
		background-color: #fff;
		color: #3e5054;
		border: 2.5px solid rgba(0, 0, 0, 0.2);
		outline: none !important;
		transition: all 0.35s;
		font-weight: 500;
		width: 100%;
	}

	button:hover {
		border: 2.5px solid var(--accent-color);
	}

	.active,
	.active:hover {
		border: 2.5px solid var(--accent-color);
		background-color: var(--accent-color-active);
	}
}

.disabled {
	background-color: rgba(0, 0, 0, 0.2) !important;
}

.unclickable {
  cursor: unset !important;
}

.color-button-wrapper {
	input {
		display: none;
	}

	width: 60px !important;
	height: 60px !important;
	button {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		outline: none !important;
		transition: all 0.35s;
		font-weight: 500;
		border-image-outset: 2px;
	}

	.active {
		border: 3px solid var(--accent-color) !important;
		box-shadow: 0 0 0 2px #fff inset;
	}

	#dark-light-option {
		border: 2px solid #fff;
	}
}

.blur-image {
	position: absolute;
	filter: blur(20px);
	clip-path: inset(0);
	transition: opacity 0.2s;
	opacity: 1;
	left: 0;
	object-fit: cover;
	top: 0;
}

.blur-image-hide {
	opacity: 0;
}
.blur-image-transition {
	opacity: 0;
}

.image-fade {
	opacity: 0;
	transition: opacity 0.5s;
	object-fit: cover;
  z-index: 10;
}
.image-fade-loaded {
	opacity: 1;
}

.thumbnail-button {
	position: relative;
	width: 100px;
	height: 100px;
	margin-right: 20px;
	margin-bottom: 20px;
}

.material-button-wrapper {
	input {
		display: none;
	}

	.image-dial {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		outline: none !important;
		transition: all 0.35s;
		font-weight: 500;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		border: 3px solid white !important;
	}

	.active {
		border: 3px solid var(--accent-color) !important;
		box-shadow: 0 0 0 2px #fff inset;
	}
}

.button-layout {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	margin-top: 25px;

	.image-text-button-wrapper:nth-child(1) > div {
		margin-top: 0;
	}

	.text-button-wrapper:nth-child(1) {
		div {
			margin-top: 0;
		}
	}

  .multi-select-text-button-wrapper:nth-child(1) {
    margin-top: 0;
  }

	.pill-button-wrapper:nth-child(1) {
		margin-right: 18px;
	}

	.color-button-wrapper {
		button {
			margin-right: 25px;
			margin-top: 15px;
		}
	}

	.material-button-wrapper {
		.image-dial {
			margin-right: 25px;
			margin-top: 15px;
		}
	}

	img {
		pointer-events: none;
	}
}

.button-layout.sidingColor-buttons,
.button-layout.cabinetColor-buttons,
.button-layout.kitchenBathroomFloorColor-buttons,
.button-layout.livingRoomFloorColor-buttons,
.button-layout.bedroomFloorColor-buttons {
	margin-top: 17px;
}

.color-text-button-wrapper {
  width: 100%;

  input {
    display: none;
  }

  span {
    .color {

    }
  }

  .imageButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 18px;
    cursor: pointer;
    transition: 0.25s all;

    .text-wrapper {
      display: flex;
      justify-content: space-between;
      pointer-events: none;
      margin-top: 0;
      border: none;
      width: 100%;
      padding-left: 22px;
      padding-right: 34px;
    }

    .button-text {
      margin-bottom: 0;
      pointer-events: none;
      font-size: 16px;
      margin-right: 20px;
    }

    .price {
      margin-bottom: 0;
      pointer-events: none;
      font-size: 16px;
    }

    img {
      max-height: 54px;
      height: 54px;
      width: 54px;
      max-width: 54px;
    }
  }

  div:hover {
    border: 2px solid var(--accent-color);
  }

  .active,
  .active:hover {
    background: var(--accent-color-active);
    border: 2px solid var(--accent-color);
    border-radius: 10px;
  }
}

.image-text-button-wrapper {
	width: 100%;

	input {
		display: none;
	}

  span {
    .color {

    }
  }

	.image-text-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 2px solid rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		border-radius: 10px;
		margin-top: 18px;
		cursor: pointer;
    overflow: hidden;
		transition: 0.25s all;

		.text-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
			pointer-events: none;
			margin-top: 0;
			border: none;
			width: 100%;
			padding-left: 22px;
			padding-right: 34px;

      .button-text {
        margin-bottom: 0;
        pointer-events: none;
        font-size: 16px;
        margin-right: 20px;
      }

      .price {
        margin-bottom: 0;
        pointer-events: none;
        font-size: 16px;
      }

		}
	}

  .image-text-container:hover {
    border: 2px solid var(--accent-color);
  }



	.active,
	.active:hover {
		background: var(--accent-color-active);
		border: 2px solid var(--accent-color);
		border-radius: 10px;
	}
}



.general-button {
	color: #111111;
	padding: 8px 20px;
	margin: 5px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	border-radius: 4px;
	border-color: #000;
	border-width: 1px;
	border-style: solid;
	font-size: 16px;
	cursor: pointer;
}

.multi-select-text-button-wrapper {
	width: 100%;

	input {
		display: none;
	}
  margin-top: 18px;

	div {
		min-height: 46px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 2.5px solid rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.25s all;
		position: relative;

		.button-text {
			margin-bottom: 0;
			pointer-events: none;
			margin-left: 50px;
			margin-right: 20px;
			font-size: 16px;
		}

		.buttonCheckBox,
		.buttonCheckBox:hover {
			min-height: unset;
			border: 1.5px solid #000000;
			box-sizing: border-box;
			border-radius: 2px;
			position: absolute;
			height: 16px;
			width: 16px;
			top: 0;
			left: 20px;
			bottom: 0;
			margin: auto;

			--background: #fff;
			--border: #d1d6ee;
			--border-active: #1e2235;
			--tick: #fff;
			transition: box-shadow 0.3s;
			box-shadow: inset 0 0 0 0 black;
			text-align: center;

			svg {
				pointer-events: none;
				fill: none;
				stroke-width: 2px;
				stroke-linecap: round;
				stroke-linejoin: round;
				position: relative;
				margin: auto;
				display: block;
				stroke: rgba(255, 255, 255, 0);
				transition: stroke 0.3s ease-in-out;
			}

			.checkedSVG {
				stroke: rgba(255, 255, 255, 1);
			}
		}
		.buttonCheckBox.checked {
			box-shadow: inset 0 0 0 11px black;
		}

		.price {
			margin-bottom: 0;
			margin-right: 25px;
			pointer-events: none;
			font-size: 16px;
		}
	}

	div:hover {
		border: 2.5px solid var(--accent-color);
	}

	.active,
	.active:hover {
		border: 2.5px solid var(--accent-color);
		box-sizing: border-box;
		border-radius: 10px;
		background: var(--accent-color-active);
	}
}
.bigCircleCheck,
.bigCircleCheck:hover {
  min-height: unset;
  box-sizing: border-box;
  stroke: white;
  stroke-width: 5px;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  position: relative;
  border: 1px solid black;
  background-color: white;

  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 0 var(--accent-color);
  text-align: center;

  svg {

    stroke: white;
    stroke-width: 5px;
    pointer-events: none;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    position: relative;
    margin: auto;
    display: block;
    stroke: rgba(255, 255, 255, 1);
    transition: stroke 0.3s ease-in-out;
  }
}

.bigCircleCheck.visited {
  border: none;
  background: none;
  background-color: transparent !important;
  box-shadow: inset 0 0 0 50px var(--accent-color);
}

.circleCheck,
.circleCheck:hover {
	min-height: unset;
	box-sizing: border-box;
	border: 1.5px solid #000000;
	height: 16px;
	width: 16px;
  background-color: white;
  border-radius: 50px;
	margin-right: 10px;
	position: relative;

	transition: box-shadow 0.3s;
	box-shadow: inset 0 0 0 0 var(--accent-color);
	text-align: center;

	svg {
		pointer-events: none;
		fill: none;
		stroke-width: 2px;
		stroke-linecap: round;
		stroke-linejoin: round;
		position: relative;
		margin: auto;
		display: block;
		stroke: rgba(255, 255, 255, 0);
		transition: stroke 0.3s ease-in-out;
	}

	.checkedSVG {
		stroke: rgba(255, 255, 255, 1);
	}
}
.circleCheck.visited {
	border: none;
	box-shadow: inset 0 0 0 11px var(--accent-color);
}

.circleCheck.selected {
	border: 1.5px solid var(--accent-color);
	box-shadow: 0 0 0 2px #fff inset;
	background-color: var(--accent-color);
}

@keyframes bounce {
	50% {
		transform: scale(1.2);
	}
	75% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}

.text-button-wrapper {
	width: 100%;

	input {
		display: none;
	}

	div {
		min-height: 46px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 2.5px solid rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		border-radius: 10px;
		margin-top: 18px;
		cursor: pointer;
		transition: 0.25s all;

		.button-text {
			margin-bottom: 0;
			pointer-events: none;
			margin-left: 25px;
			margin-right: 20px;
			font-size: 16px;
		}

		.price {
			margin-bottom: 0;
			margin-right: 25px;
			pointer-events: none;
			font-size: 16px;
		}
	}

	div:hover {
		border: 2.5px solid var(--accent-color);
	}

	.active,
	.active:hover {
		border: 2.5px solid var(--accent-color);
		box-sizing: border-box;
		border-radius: 10px;
		background: var(--accent-color-active);
	}
}

button:disabled {
	opacity: 0.5;
	border: 2px solid rgba(62, 80, 84, 0.3) !important;
}

/* #endregion BUTTONS */

/* #region STEP BARS */

.step-bar {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-right: 0 !important;
	padding-left: 0 !important;
  column-gap: 8px;

	.item {
    height: 50px;
		position: relative;
		z-index: 2;
		cursor: pointer;

		div {
			img {
				margin-right: 8px;
				margin-top: -3.5px;
			}

			pointer-events: none;
		}
		border-bottom: 4px solid rgba(0, 0, 0, 0);
	}
	.active {
		border-bottom: 4px solid var(--accent-color);
	}

	.base-underline {
		border-top: 2px solid #e8e8e8;
		width: 100%;
		position: relative;
		z-index: 0;
	}
}

.modal-header {
	.step-bar {
		display: flex;
		justify-content: start;
		flex-wrap: wrap;
		width: 80%;
		padding-left: 0;
		padding-top: 10px;
		height: 45px;

		p {
			margin-bottom: -2px;
			padding-bottom: 10px;
			position: relative;
			z-index: 2;
			opacity: 0.5;
			cursor: pointer;
		}

		p {
			margin-right: 34px;
		}

    p:last-child {
      margin-right: 0;
    }

		.active {
			border-bottom: 4px solid var(--accent-color);
			opacity: 1;
		}

		.base-underline {
			display: none;
		}
	}
}

/* #endregion STEP BARS */

/* #region MODAL */
.modal-dialog {
	max-width: 576px;
  line-height: 1.5;

	.modal-content {
		min-height: 425px;

		.modal-header {
			padding-bottom: 0;
		}

		.modal-body {
			justify-content: center;
			align-items: center;

			.modal-text {
				margin: 0 20px 20px 20px;
			}
		}

		.modal-footer {
			border: none;
		}
	}
}
/* #endregion MODAL */

/* #region GOOGLE SEARCH BAR */
.search-location-input {
	max-width: 400px;
	width: 400px;

	.input-icons-wrapper {
		position: relative;

		#address-input {
			width: 100%;
			margin-top: 20px;
			margin-bottom: 20px;
			outline: none;
			border: none;
			border-bottom: 1.5px solid rgba(0, 0, 0, 0.45);
		}

		img {
			position: absolute;
			top: 13px;
			right: 10px;
			z-index: 2;
		}
	}

	.error-message {
		margin-top: -15px;
	}
}

/* #endregion GOOGLE SEARCH BAR */

/* #region CONTACT FORM */

.form-wrapper {
	width: 100%;
	/*     text-align: center; */
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;

	.input-wrapper {
		width: 100%;
		max-width: 400px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.error-message {
			margin-top: -15px;
		}
	}

	.question-wrapper {
		width: 100%;

		.question {
			width: 100%;
		}
	}

	.text-input-wrapper {
		width: 100%;

		input {
			width: 100%;
			margin-top: 20px;
			margin-bottom: 20px;
			outline: none;
			border: none;
			border-bottom: 1.5px solid rgba(0, 0, 0, 0.45);
		}
	}

	#button-form-submit {
		display: none;
	}
}

.error-message {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;

	p {
		margin-bottom: 0;
		margin-left: 5px;
		color: #f96353;
	}
}

.error-input {
	border-bottom: 2px solid #f96353 !important;
}

/* #endregion CONTACT FORM */

/* #region MISC  */

.one-line {
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		margin-bottom: 4px;
	}
}

.one-line-left {
	display: flex;
	align-items: center;

	p {
		width: 40%;
		text-align: left;
	}
}

.mobile-logo {
	display: none;
	height: 50px;
}

/* The Modal (background) */
.fullDiv {
	position: absolute; /* Stay in place */
  padding: inherit;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.modal-backdrop {
  z-index: 6000000;
}

/* The Modal (background) */
.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 7000000; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.eighty-full {
	width: 80%;
	height: 80%;
}

/* Modal Content/Box */
.modal-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fefefe;
	padding: 20px;
	border: 1px solid #888;
	overflow: auto;
}

.MuiPopover-root {
  z-index: 70000001 !important;
}

.modal-without-transform {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  align-content: center;
  justify-items: center;
  z-index: 100050; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content-without-transform {
  position: relative;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  overflow: auto;
  margin: auto;
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	width: 30px;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}


/* #endregion MISC  */
// react-select library override
.css-26l3qy-menu {
  z-index: 5000100 !important;
}
